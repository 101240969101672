<template>
  <vs-row vs-justify="center">       
    <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
      <vs-card>       
          <div class="d-md-flex align-items-center">     
              <div>
                  <h3>Score</h3>
              </div>              
              <div class="flex">
                  <vs-button color="primary" type="flat" icon="sync" v-on:click="UpdateScore">Recalcular</vs-button>
              </div>              
          </div>
        <SurveyScoreTable :ScoreTable="SurveyScoreT" :key="SurveyScoreT"></SurveyScoreTable>
      </vs-card>
    </vs-col>  
  </vs-row>
</template>
<script>
// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////    

    import { Surveys } from "../../../services/Arena.js";
    const sv = new Surveys;

    import SurveyScoreTable from "../survey-table/SurveyScoreTable.vue";

// //////////////////////////////////////////
export default {
  name: "Survey Score",
  data: () => ({
      title: "Survey Score",
      SurveyStruct: {},
      resp1: {},
      SurveyScoreT: []   
  }),
  components: {
      SurveyScoreTable,    
        },
        created()
        {

            this.$vs.loading();

            //GetStruct GetScore

            sv.GetScoreGeral(this.$route.params.id).then((resp) => {
                this.setSideBar(false);
                this.resp1 = resp;
                this.SurveyStruct = resp.data.survey;
                this.SurveyScoreT = resp.data.scoreChapTable;
                this.$store.commit("SET_SurveyStruct", this.SurveyStruct);
                this.$store.commit("SET_SurveyScoreTable", this.SurveyScoreTable);
                this.$vs.loading.close();
              
            }, (err) => {
                    this.$vs.loading.close();
                    alert(err);
            });

        },
         methods: {
             setSideBar(val) {
            this.$store.commit("TOGGLE_REDUCE_SIDEBAR", val);
             },
             UpdateScore()
             {

                 this.$vs.loading();

                 //GetStruct GetScore

                 sv.CalculateScoreGeral(this.$route.params.id).then((resp) => {
                     this.setSideBar(false);
                     this.resp1 = resp;
                     this.SurveyStruct = resp.data.survey;
                     this.SurveyScoreT = resp.data.scoreChapTable;
                     this.$store.commit("SET_SurveyStruct", this.SurveyStruct);
                     this.$store.commit("SET_SurveyScoreTable", this.SurveyScoreTable);
                     this.$vs.loading.close();

                 }, (err) => {
                         this.$vs.loading.close();
                         alert(err)
                 } );

             },
        }
};
</script>