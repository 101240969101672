<template>
    <div>        
        <div v-bar class="vs-scrollable" style="height: 393px;">
            <div>
                <vs-table :data="ScoreTable" class="text-nowrap">
                    <template slot="thead">
                        <vs-th>Capítulo</vs-th>
                        <vs-th>Nota</vs-th>
                        <vs-th>Nota Algoritmo</vs-th>
                        <!--<vs-th>Fator</vs-th>
    <vs-th>Ponderada</vs-th>-->
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :state="data[indextr].survey_structure_is_mother == 1 ?'success':null" class="border-top" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                <div class="d-flex no-block align-items-center">
                                    <!-- <div v-if="data[indextr].survey_structure_is_mother == 1"> -->
                                    <!--
            <div v-if="data[indextr].survey_structure_is_mother == 1">
                <h5 class="mb-0 font-16 font-medium">{{ data[indextr].survey_structure_code }} - {{ data[indextr].survey_structure_text_es }}</h5>
            </div>
            <div v-else>
                <router-link :to='getRoute(data[indextr].survey_structure_code)'> <h5 class="mb-0 font-16 font-medium">{{ data[indextr].survey_structure_code }} - {{ data[indextr].survey_structure_text_es }}</h5></router-link>
            </div>
                -->
                                    <router-link :to='getRoute(data[indextr].survey_structure_code)'> <h5 class="mb-0 font-16 font-medium">{{ data[indextr].survey_structure_code }} - {{ data[indextr].survey_structure_text_es }}</h5></router-link>
                                </div>
                            </vs-td>
                            <vs-td>{{ data[indextr].nota }}</vs-td>
                            <vs-td>{{ data[indextr].nova_nova }}</vs-td>
                            <!--
    <vs-td>{{ data[indextr].factor }}</vs-td>
    <vs-td>{{ notaPonderada(indextr) }} </vs-td>
        -->
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>

    </div>
</template>
<script>
    
    

    export default {
            name: "SurveyChapStruct",
      data: () => ({
            title: "Estrutura Survey",
          
        // Data for data[indextr]
        RankingList: [
        ]
        }),
        props:
        {
            ScoreTable: []
        },
        components: {
    
        },
        created() {    
            
    
        },
        methods:
        {
            notaPonderada(idx) {
                var n = this.ScoreTable[idx].nova_nova * this.ScoreTable[idx].factor;
                return n;
            },
            getRoute(id) {
                return "/surveys/struct2/" + id;
                //return id;
            },

            temSubChapters(idx) {
                if (this.SurveyStruct.chapters[idx].subChapters != null)
                    return true
                else
                    return false;
            },
            temReqs(idx) {
                if (this.SurveyStruct.chapters[idx].requirements != null)
                    return true
                else
                    return false;
            },
            
        }
};
</script>
